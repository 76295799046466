.Header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
}

.RangeButton {
    font-weight: 500;
    font-size: 1.5rem;
    padding: 0.4rem 0.6rem;
    border-radius: 0.5rem;
}

.RangeButton.active {
    border: 1px solid black;
}

.Wrapper {
    width: 100%;
    height: 100%;
    max-height: 30rem;
    padding: 0.5rem;
    box-sizing: border-box;
    overflow: visible;
}