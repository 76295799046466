.Wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overlay: hidden;
}

.Nav {
    height: 3rem;
    border-bottom: 1px solid black;
    flex-shrink: 0;
    flex-grow: 0;
}

.Main {
    padding: 0.5rem;
    overflow-y: auto;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: auto;
    width: 30rem;
    max-width: 100%;
    box-sizing: border-box;
    margin-inline: auto;
}

.BottomNav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 3rem;
    flex-shrink: 0;
    flex-grow: 0;
    border-top: 1px solid black;
}

.NavLink {
    padding: 0.2rem;
    border-radius: 0.5rem;
}

.NavLink.active {
    border: 1px solid black;
}

