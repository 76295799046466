.Summary {
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    height: 3rem;
    font-weight: 500;
    justify-content: space-between;
}

.List {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ListItem {
    display: flex;
    height: 2rem;
    align-items: center;
    justify-content: space-between;
}

.List > .ListItem + .ListItem {
    margin-top: 0.5rem;
}

.ActionGroup {
    display: flex;
}


.Link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    color: black;
    text-decoration: none;
}