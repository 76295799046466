.Header {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0.5rem;
}

.RecipeSelect {
    display: flex;
    align-items: center;
}

.RecipeSelect.FormInput {
    flex-grow: 1;
}

.SubmitButton {
    margin-top: 1rem;
}

.Component {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}
